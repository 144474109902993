import React from 'react'
import HigherPic from '../../assets/higher_art_4.jpg'
import Shirt1 from '../../assets/shop/Dove-T-shirt-3.jpg'
import Shirt2 from '../../assets/shop/Dove-T-shirt-2.jpg'
import Vinyl from '../../assets/shop/Vinyl-Image.jpg'
// import placeholderMusician from '../../assets/shop/4-Tips-for-Starting-Out-as-a-Musician.webp'

const shopItemPreviews = [Shirt1, Shirt2, Vinyl]

export default function Superficial() {
  return (
    <>
        <Higher />
        <Store />
        <About />
        <Footer />
    </>
  )
}

function Higher() {
    return (
        <div id='superficial-higher-container' className='superficial-container'>
            <div className='higher-art-container'>
                <h1>In progress...</h1>
                <img alt='' id='higher-art' src={HigherPic} />
            </div>
        </div>
    )
}

function Store() {
    return (
        <div id='superficial-store-container' className='superficial-container'>
            <h1>Shop</h1>
            <div className='store-items-container'>
                {shopItemPreviews.map(el => 
                <div className='store-item-container'>
                    <img className='store-item' height={300} width={300} src={el} alt='' />
                    <span>SHOP NOW</span>
                </div>
                )}
            </div>
        </div>
    )
}

function About() {
    return (
        <div id='about-container' className='superficial-container'>
            <div className='epk-section'>
                <h3>Your photos here</h3>
                {/* <img id='musician-pic' src={placeholderMusician} alt='' /> */}
            </div>
            <div className='epk-section'>
                <h3>About Theo</h3>
                {/* <p>Theo Omni is a musician that...</p>  */}
            </div>
            <div className='epk-section'>
                <h3>Featured In</h3>
                {/* <a href='https://google.com'>Ear</a> */}
            </div>
        </div>
    )
}

function Footer() {
    return (
        <div id='footer-container' className='superficial-container'></div>
    )
}
