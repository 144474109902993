import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';

function Nav() {
  return (
    <nav className="nav">
        <Link className="glow">THEO OMNI</Link>
        <HashLink to='#superficial-higher-container'>Higher</HashLink>
        <HashLink to='#superficial-store-container'>Store</HashLink>
        <HashLink to='#about-container'>About</HashLink>
    </nav>
  )
}

export default Nav