import React from 'react'
import LayersNav from './LayersNav'

export default function HigherTwo() {
  return (
    <div className="higher-two">
        <LayersNav />
        <div className="sections">
            <div className="section" id="section-1">Section 1 Content</div>
            <div className="section" id="section-2">Section 2 Content</div>
            <div className="section" id="section-3">Section 3 Content</div>
            <div className="section" id="section-4">Section 4 Content</div>
        </div>
  </div>
  )
}
