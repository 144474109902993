import { useState, useEffect } from 'react'

const sections = ['Layer 1', 'Layer 2', 'Layer 3', 'Layer 4'];

export default function LayersNav() {
    const [activeSection, setActiveSection] = useState(0);

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const sectionElements = document.querySelectorAll('.section');
      sectionElements.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(index);
        }
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <div className="progress-container">
        {sections.map((section, index) => (
          <div key={index} className={`progress-item ${index <= activeSection ? 'active' : ''}`}>
            <div className='dot-line-container'>
                <div className="dot"></div>
                {<div className="line"></div>}
            </div>
            <div className="label">{section}</div>
          </div>
        ))}
      </div>
    );
  };
